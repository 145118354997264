import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: #f8f8f8;
    font-family: "Ropa Sans", "sans-serif";
    font-size: 16px;
    height: 100%;
    line-height: 1.5;
    margin: 0;
    min-height: 100vh;
    min-width: 320px;
    padding: 0;
    position: relative;
    scroll-behavior: smooth;
    width: 100%;
  }
  h2 {
    font-size: 1.6rem;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
    
  *:focus {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: 400;
    margin: 0;
  }
    
  a {
    text-decoration: none;
  }

  @media screen and (min-width: 576px) {
    body{
      font-size: 17px;
    }
  }
  @media screen and (min-width: 768px) {
    body{
      font-size: 18px;
    }
  }
  @media screen and (min-width: 992px) {
    body{
      font-size: 19px;
    }
  }
  @media screen and (min-width: 1200px) {
    body{
      font-size: 20px;
    }
  }


  ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #E3E3E3;
    }
    ::-webkit-scrollbar-thumb {
      background: #686868;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #333333;
    }

  @font-face {
    font-family: 'Ropa Sans';
    src: local("Ropa Sans"), url('${require('../assets/fonts/RopaSans-Regular.ttf')}') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`;

export default GlobalStyle;
