import React, { Component } from 'react';

export const Context = React.createContext();

export class Provider extends Component {
  state = {
    isMenuVisible: false,
    emailStatus: '',
  };
  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          toggleMenu: () => {
            this.setState({ isMenuVisible: !this.state.isMenuVisible });
          },
          toggleEmailStatus: () => {
            this.setState({ emailStatus: !this.state.emailStatus });
          },
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
