import styled from 'styled-components';

const CallToAction = styled.button`
  background-color: #d26426;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin: 5px 0;
  padding: 5px 10px;
  text-transform: uppercase;
  &.right {
    float: right;
  }
  &:hover {
    background-color: #ff9759;
  }
`;

export default CallToAction;
