import React from 'react';
import styled from 'styled-components';
import { Context } from '../../contexts/context';

const Menu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const MenuItem = styled.li`
  margin: 25px 0;
`;

const Menulist = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: center;
`;

const RightTop = styled.div`
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 1.6rem;
`;

export default (_) => (
  <Context.Consumer>
    {(context) => (
      <Menu>
        <RightTop onClick={context.toggleMenu}>X</RightTop>
        <Menulist>
          <MenuItem>
            <Link href="/">Home</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/diensten">Diensten</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/over">Over Reynaert</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/blog">Blog</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/algemene-voorwaarden">Algemene voorwaarden</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/niche-relaties">Niche-relaties</Link>
          </MenuItem>
          <MenuItem>
            <Link href="/contact">Contact</Link>
          </MenuItem>
        </Menulist>
      </Menu>
    )}
  </Context.Consumer>
);
