import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Fox from '../../assets/images/logo.svg';
import CallToAction from '../../components/CallToAction';
import { Context } from '../../contexts/context';
import Menu from './Menu';
import NavItem from './NavItem';

library.add(faBars);
export default (_) => {
  return (
    <Context.Consumer>
      {(context) => (
        <HeaderWrapper>
          <NavFlex p={3}>
            <LogoLink to="/">
              <ImageHelper>
                <Styledimg alt="logo advocaat" src={Fox} />
              </ImageHelper>
              <TextHelper>
                <StyledReynaert>Frank Reynaert</StyledReynaert>
                <StyledName>advocaat</StyledName>
              </TextHelper>
            </LogoLink>
            <MenuWrapper>
              <CallToAction onClick={context.toggleMenu}>
                <Stylediwhite icon="bars" />
                Menu
              </CallToAction>
              {context.state.isMenuVisible && <Menu />}
            </MenuWrapper>
            <Links>
              <NavItem to="/" activeStyle={activeStyle}>
                Home
              </NavItem>
              <NavItem to="/diensten" activeStyle={activeStyle}>
                Diensten
              </NavItem>
              <NavItem to="/over" activeStyle={activeStyle}>
                Over Reynaert
              </NavItem>
              <NavItem to="/blog" activeStyle={activeStyle}>
                Blog
              </NavItem>
              <NavItem to="/algemene-voorwaarden" activeStyle={activeStyle}>
                Algemene voorwaarden
              </NavItem>
              <NavItem to="/niche-relaties" activeStyle={activeStyle}>
                Niche-relaties
              </NavItem>
              <NavItem to="/contact" activeStyle={activeStyle}>
                Contact
              </NavItem>
            </Links>
          </NavFlex>
        </HeaderWrapper>
      )}
    </Context.Consumer>
  );
};

const activeStyle = {
  color: '#d26426',
  borderTop: '2px solid #d26426',
};

const HeaderWrapper = styled.nav`
  height: 100px;
  width: 100%;
  background-color: rgb(33, 37, 40);
`;

const NavFlex = styled(Flex)`
  height: 100px;
  max-width: 1128px;
  justify-content: space-between;
  margin: 0 auto;
`;

const Links = styled.div`
  align-self: center;
  @media (max-width: 947px) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  color: white;
  flex: 1;
  text-align: right;
  align-self: center;
  font-size: 1.2rem;
  margin-right: 10px;
  @media only screen and (min-width: 948px) {
    display: none;
  }
`;

const Stylediwhite = styled(FontAwesomeIcon)`
  color: white;
  margin-right: 5px;
  height: 1rem;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-self: center;
  align-items: center;
  min-height: 70px;
`;

const TextHelper = styled(Box)`
  align-self: center;
  align-items: center;
  padding-left: 5px;
`;
const Styledimg = styled.img`
  vertical-align: middle;
  height: 70px;
  padding-right: 5px;
  @media (max-width: 1105px) {
    height: 35px;
  }
`;

const ImageHelper = styled(Box)`
  margin-right: 5px;
  align-self: center;
  padding-right: 5px;
  border-right: 1px solid white;
`;

const StyledReynaert = styled.p`
  color: white;
  font-size: 1.5rem;
  @media (max-width: 1105px) {
    font-size: 1rem;
  }
`;

const StyledName = styled.p`
  color: #d26426;
  font-size: 1.5rem;
  @media (max-width: 1105px) {
    font-size: 1rem;
  }
`;
