import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faExternalLinkAlt, faFileDownload, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components';
import AlgemeneVoorwaarden from '../../assets/files/algemene-voorwaarden.pdf';
import Fox from '../../assets/images/logo.svg';

export default (_) => (
  <Footer>
    <FooterFlex flexWrap="wrap">
      <Box width={[1, 1 / 2, 1 / 4]} p={3}>
        <Flex>
          <ImageHelper>
            <Styledimg alt="logo advocaat" src={Fox} />
          </ImageHelper>
          <Box>
            <StyledReynaert>Frank Reynaert</StyledReynaert>
            <StyledName>advocaat</StyledName>
          </Box>
        </Flex>

        <Footerlist>
          <FooterListItem>REYNAERT FRANK</FooterListItem>
          <FooterListItem>BE0679.457.482</FooterListItem>
          <FooterListItem />
        </Footerlist>
      </Box>
      <Box width={[1 / 2, 1 / 4]} p={3}>
        <Footerh2>Contact info</Footerh2>
        <Footerlist>
          <FooterListItem>
            <Flex>
              <Box>
                <Styledi icon={faPhone} />
              </Box>
              <Box>
                <Styleda href="tel:+32475247856">+32 475 24 78 56</Styleda>
              </Box>
            </Flex>
          </FooterListItem>
          <FooterListItem>
            <Flex>
              <Box>
                <Styledi icon={faEnvelope} />
              </Box>
              <Box>
                <Styleda href="mailto:frank@reynaertadvocaten.be">frank@reynaertadvocaten.be</Styleda>
              </Box>
            </Flex>
          </FooterListItem>
          <FooterListItem>
            <Flex>
              <Box>
                <Styledi icon={faMapMarkerAlt} />
              </Box>
              <Box>Zonstraat 21, B 8670 Koksijde</Box>
            </Flex>
          </FooterListItem>
        </Footerlist>
      </Box>
      <Box width={[1, 1 / 2, 1 / 4]} p={3}>
        <Footerh2>Social media</Footerh2>
        <Footerlist>
          <FooterListItem>
            <Styleda href="https://www.facebook.com/ReynaertAdvocaten/" target="_blank" rel="noopener noreferrer">
              <Styledi icon={faFacebook} />
              Facebook
              <Styledi className="inline" icon={faExternalLinkAlt} />
            </Styleda>
          </FooterListItem>
          <FooterListItem>
            <Styleda href="https://www.linkedin.com/company/reynaert-advocaten/" target="_blank" rel="noopener noreferrer">
              <Styledi icon={faLinkedin} />
              LinkedIn
              <Styledi className="inline" icon={faExternalLinkAlt} />
            </Styleda>
          </FooterListItem>
        </Footerlist>
      </Box>
      <Box width={[1, 1 / 2, 1 / 4]} p={3}>
        <Footerh2>Site map</Footerh2>
        <Footerlist>
          <FooterListItem>
            <Styleda href="/">Home</Styleda>
          </FooterListItem>
          <FooterListItem>
            <Styleda href="/diensten">Diensten</Styleda>
          </FooterListItem>
          <FooterListItem>
            <Styleda href="/over">Over Reynaert</Styleda>
          </FooterListItem>
          <FooterListItem>
            <Styleda href="/algemene-voorwaarden">Algemene voorwaarden</Styleda>
          </FooterListItem>
          <FooterListItem>
            <Styleda href="/niche-relaties">Niche-relaties</Styleda>
          </FooterListItem>
          <FooterListItem>
            <Styleda href="/contact">Contact</Styleda>
          </FooterListItem>
        </Footerlist>
      </Box>
    </FooterFlex>
    <Flex justifyContent="center" flexDirection="column" p={3}>
      <Footerh2>Documenten</Footerh2>
      <Styleda href={AlgemeneVoorwaarden} download>
        <Styledi icon={faFileDownload} />
        Algemene voorwaarden
      </Styleda>
    </Flex>
    <Flex justifyContent="center" p={3}>
      <p>
        Copyright © {new Date().getFullYear()} Frank Reynaert - Website door{' '}
        <Styleda href="https://upshift.be" target="_blank" rel="noopener noreferrer" className="external">
          upshift
          <Styledi className="inline" icon={faExternalLinkAlt} />
        </Styleda>
      </p>
    </Flex>
  </Footer>
);

const Footer = styled.footer`
  background-color: rgb(33, 37, 40);
  color: white;
  font-size: 0.9rem;
  padding-top: 20px;
`;

const Footerh2 = styled.h2`
  font-size: 1rem;
  color: #d26426;
  margin-top: 0;
  align-self: center;
`;

const FooterFlex = styled(Flex)`
  max-width: 1024px;
  margin: 0 auto;
`;

const Footerlist = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Styledi = styled(FontAwesomeIcon)`
  margin-right: 5px;
  height: 1rem;
  color: #d26426;
  &.inline {
    color: white;
    margin-left: 5px;
  }
`;

const Styledimg = styled.img`
  vertical-align: middle;
  height: 30px;
  @media (max-width: 650px) {
    height: 30px;
  }
`;

const ImageHelper = styled(Box)`
  margin-right: 5px;
  align-self: center;
  padding-right: 5px;
  border-right: 1px solid white;
`;

const StyledReynaert = styled.p`
  color: white;
  font-size: 0.8rem;
  margin: 2px 0;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`;

const StyledName = styled.p`
  color: #d26426;
  font-size: 0.8rem;
  margin: 2px 0;
  letter-spacing: normal;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`;

const FooterListItem = styled.li`
  margin: 10px 0;
`;

const Styleda = styled.a`
  color: white;
  align-self: center;
  text-decoration: underline;
`;
