import { Link } from 'gatsby';
import styled from 'styled-components';

const NavItem = styled(Link)`
  color: white;
  margin-left: 25px;
  padding-top: 3px;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #d26426;
    transition: all 0.2s ease-in-out;
  }
`;

export default NavItem;
